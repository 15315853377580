const btnScrollTop = document.getElementById("scrollTop")

btnScrollTop.addEventListener( 'click',() => {
    window.scrollTo(0, 0)
} )

window.onscroll = () => {
    if (window.scrollY < 300) {
        btnScrollTop.classList.remove("scrollTopActivo")
    } else {
        btnScrollTop.classList.add("scrollTopActivo")
    }
}


